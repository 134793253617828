<template>
  <div class="app-container">
    <el-form
      :inline="true"
      :model="queryForm"
      class="demo-form-inline account-top search-header"
      ref="searchFormRef"
      label-width="80px"
    >
      <!-- <el-form-item label="用户名">
        <el-input v-model="queryForm.username" placeholder="用户名"></el-input>
      </el-form-item> -->
      <el-form-item label="ID">
        <el-input
          v-model="queryForm.id"
          placeholder="帐号ID"
          clearable
          style="width:100%"
        ></el-input>
      </el-form-item>

      <el-form-item label="帐号状态">
        <el-select
          style="width:100%"
          v-model="queryForm.status"
          placeholder="帐号状态"
          clearable
        >
          <el-option label="正常" :value="1"></el-option>
          <el-option label="异常" :value="0"></el-option>
          <el-option label="失效" :value="-1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="搜索">
        <el-input
          style="width:100%"
          v-model="queryForm.search"
          placeholder="用户名备注等"
        ></el-input>
      </el-form-item>
      <el-form-item label="风险类型" v-show="searchType">
        <el-select
          style="width:100%"
          v-model="queryForm.is_risk"
          placeholder="风险类型"
          clearable
        >
          <el-option label="有风险" value="true"></el-option>
          <el-option label="无风险" value="false"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="帐号类型"
        v-if="userInfo.is_staff"
        v-show="searchType"
      >
        <el-select
          style="width:100%"
          v-model="queryForm.is_test"
          placeholder="帐号类型"
          clearable
        >
          <el-option label="演示帐号" value="true"></el-option>
          <el-option label="正式帐号" value="false"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="分组" v-show="searchType">
        <el-select
          style="width: 100%;"
          v-model="queryForm.group"
          placeholder="请选择分组"
          filterable
          clearable
        >
          <el-option label="未分组" value="w"></el-option>
          <el-option
            v-for="item in groupOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="使用者" v-if="userInfo.is_staff" v-show="searchType">
        <el-input
          style="width:100%"
          v-model="queryForm.user"
          placeholder="用户id"
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handleSearch">查询</el-button>
        <el-link
          type="primary"
          style="margin-left: 10px;"
          @click="showSearchFun"
        >
          <span v-if="searchType">
            收起
          </span>
          <span v-else>
            更多检索条件
          </span>

          <i class="el-icon-arrow-up" v-if="searchType"></i>
          <i class="el-icon-arrow-down" v-else></i>
        </el-link>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      :height="windowsHeight - 180 - searchHeight"
    >
      <el-table-column prop="id" label="ID" width="100" />
      <el-table-column prop="user_name" label="用户名"></el-table-column>
      <!-- <el-table-column prop="name" label="昵称"></el-table-column> -->
      <!-- <el-table-column prop="description" label="简介"></el-table-column> -->
      <el-table-column prop="remark" label="备注">
        <template slot-scope="scope" class="btnEdit">
          <el-input
            v-if="scope.row.edit"
            size="mini"
            type="textarea"
            v-model="scope.row.remark"
            @blur="saveEdit(scope.row.id, { remark: scope.row.remark })"
          ></el-input>
          <span v-if="!scope.row.edit">{{ scope.row.remark }}</span>
          <i
            style="
              display: block !important;
              float: right !important;
              color: blue !important;
            "
            v-if="!scope.row.edit"
            icon="el-icon-edit"
            @click="cellClick(scope.row)"
            class="el-icon-edit"
          ></i>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="login_account"
        label="登录帐号"
        width="180"
      ></el-table-column>
      <el-table-column label="主页链接" prop="index_url" width="250">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            content="点击复制"
            placement="top"
          >
            <div
              class="tag-read"
              style="cursor: pointer"
              @click="copy(scope.row.index_url)"
              :data-clipboard-text="copyValue"
            >
              {{ scope.row.index_url }}
            </div>
          </el-tooltip>
        </template>
      </el-table-column> -->
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="getStatusTip(scope.row.status, statusMap)"
            placement="top"
          >
            <el-tag
              :type="getStatusType(scope.row.status, statusMap)"
              disable-transitions
            >
              {{ getStatusLabel(scope.row.status, statusMap) }}
            </el-tag>
          </el-tooltip>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="profile_id" label="配置文件ID"></el-table-column> -->
      <!-- <el-table-column prop="is_risk" label="风险">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.is_risk ? 'danger' : 'success'"
            disable-transitions
          >
            {{ scope.row.is_risk ? '有风险' : '无风险' }}
          </el-tag>
        </template>
      </el-table-column> -->
      <el-table-column prop="is_test" label="演示帐号" v-if="userInfo.is_staff">
        <template slot-scope="scope">
          <!-- {{ scope.row.is_test ? '是' : '否' }} -->
          <el-popconfirm
            title="确定更改帐号的状态吗?"
            @confirm="
              saveEdit(scope.row.id, { is_test: scope.row.is_test }, $event)
            "
            @cancel="scope.row.is_test = !scope.row.is_test"
          >
            <el-switch
              v-model="scope.row.is_test"
              active-color="#13ce66"
              inactive-color="#ff4949"
              slot="reference"
            ></el-switch>
          </el-popconfirm>

          <!-- :change="saveEdit(scope.row.id, { is_test: scope.row.is_test })" -->
        </template>
      </el-table-column>
      <el-table-column label="分组">
        <template slot-scope="scope">
          <el-tag
            type="warning"
            disable-transitions
            v-if="scope.row.group_entity && scope.row.group_entity.name"
            closable
            @close="closeGroup(scope.row)"
          >
            {{ scope.row.group_entity.name }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="标签">
        <template slot-scope="scope">
          <div v-if="scope.row.tag_links_entity">
            <el-tag
              closable
              style="margin: 0 5px 5px 0;"
              disable-transitions
              v-for="(item, i) in scope.row.tag_links_entity"
              :key="i"
              @close="closeTag(item)"
            >
              {{ item.tag.tag }}
            </el-tag>
            <el-input
              v-if="scope.row.tag_type"
              v-model="scope.row.tagName"
              size="small"
              style="width: 60px;"
              :ref="'inputField' + scope.row.id"
              @blur="inputBlurFun(scope.row)"
            ></el-input>
            <el-button v-else size="small" @click="createTag(scope.row)">
              + 新标签
            </el-button>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="user_entity"
        label="使用者"
        v-if="userInfo.is_staff"
        width="150"
      >
        <template slot-scope="scope">
          {{
            scope.row.user_entity
              ? scope.row.user_entity.oauth_account
                ? scope.row.user_entity.oauth_account
                : scope.row.user_entity.username
              : null
          }}
        </template>
      </el-table-column>
      <el-table-column label="使用者备注" v-if="userInfo.is_staff" width="350">
        <template slot-scope="scope">
          {{ scope.row.user_entity ? scope.row.user_entity.note : null }}
        </template>
      </el-table-column>
      <el-table-column
        prop="last_login_time"
        label="最后登录时间"
        width="170"
      ></el-table-column>
      <el-table-column
        prop="regist_time"
        width="170"
        label="注册时间"
      ></el-table-column> -->
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="detailFun(scope.row)">
            详情
          </el-button>
          <el-button
            size="mini"
            type="text"
            @click="handlePostTask(scope.$index, scope.row)"
          >
            发帖
          </el-button>
          <el-button
            size="mini"
            style="margin-left: 0;"
            type="text"
            @click="markFun(scope.row)"
          >
            标记
          </el-button>
          <el-button size="mini" type="text" @click="groupFun(scope.row)">
            分组
          </el-button>
          <!-- <el-button
            size="mini"
            type="text"
            v-show="isshow"
            @click="handleAccount(scope.row)"
          >
            查看
          </el-button> -->
          <!-- <el-button
            size="mini"
            type="text"
            @click="handleForwardTask(scope.$index, scope.row)"
          >
            转帖
          </el-button>
          <el-button
            size="mini"
            type="text"
            @click="handleCommentTask(scope.$index, scope.row)"
          >
            评论
          </el-button>

          <el-button
            size="mini"
            type="text"
            @click="handleLikeTask(scope.$index, scope.row)"
          >
            点赞
          </el-button> -->
          <!-- <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="queryForm.pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryForm.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="count"
    ></el-pagination>
    <!-- 发帖开始 -->
    <el-dialog
      :visible.sync="postTaskDialogShow"
      title="发帖任务"
      :before-close="clickClosePostTaskDialog"
    >
      <PostTask
        v-if="postTaskDialogShow"
        :account-id="accountId"
        @dialogStatus="postTaskDialogStatus"
      />
    </el-dialog>
    <!-- 发帖结束 -->
    <!---查看账号使用者-->
    <el-dialog title="提示" :visible.sync="accountDialog" width="50%" left>
      <el-table
        :data="userList"
        header-row-class-name="tableheader"
        style="width: 100%; margin-top: 10px"
        :row-style="{ height: 0 + 'px' }"
        :cell-style="{ padding: '5px 0' }"
      >
        <el-table-column prop="id" label="ID" width="50px"></el-table-column>
        <el-table-column
          prop="username"
          label="用户名"
          width="150px"
        ></el-table-column>
        <el-table-column prop="note" label="备注"></el-table-column>

        <el-table-column
          prop="create_time"
          label="创建时间"
          width="100px"
        ></el-table-column>
      </el-table>
      <!--分页组件开始-->
      <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        :currentPage="current"
        :page-size="limit"
        layout="total, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
      <!--分页组件结束-->
      <span slot="footer" class="dialog-footer">
        <el-button @click="accountDialog = false">取 消</el-button>
        <el-button type="primary" @click="accountDialog = false">
          确 定
        </el-button>
      </span>
    </el-dialog>
    <!-- 转帖开始 -->

    <!-- <el-dialog
      :visible.sync="forwardTaskDialogShow"
      title="转帖任务"
      :before-close="clickCloseForwardTaskDialog"
    >
      <ForwardTask
        v-if="forwardTaskDialogShow"
        :account-id="accountId"
        @dialogStatus="forwardTaskDialogStatus"
      />
    </el-dialog> -->
    <!-- 转帖结束 -->

    <!--评论开始-->
    <!-- <el-dialog
      :visible.sync="commentTaskDialogShow"
      title="评论任务"
      :before-close="clickCloseCommentTaskDialog"
    >
      <CommentTask
        v-if="commentTaskDialogShow"
        :account-id="accountId"
        @dialogStatus="commentDialogStatus"
      ></CommentTask>
    </el-dialog> -->
    <!--评论结束-->

    <!--点赞开始-->
    <!-- <el-dialog :visible.sync="likeTaskDialogShow" title="点赞任务">
      <LikeTask
        v-if="likeTaskDialogShow"
        :account-id="accountId"
        @dialogStatus="likeDialogStatus"
      ></LikeTask>
    </el-dialog> -->
    <!--点赞结束-->
    <!-- 打标签 -->
    <tagDialog ref="tagDialogRef" @updateFun="fetchData" />
    <!-- 分组 -->
    <groupDialog ref="groupDialogRef" @updateFun="fetchData" />
    <!-- 详情 -->
    <ytbDetailDialog ref="ytbDetailDialogRef" />
  </div>
</template>

<script>
import {
  getAccountList,
  updateAccount,
  getAssignByAccount
} from '@/api/youTube'
import { delApi as delTagApi, addApi as addTagApi } from '@/api/mark/youtube'
import { get_or_createApi } from '@/api/mark/index'
import { group_all_Api } from '@/api/group'
import PostTask from '@/components/youTube/postTask/index'
import tagDialog from '@/views/label/components/Label_YTB_Dialog.vue'
// import CommentTask from '@/components/twitter/commentTask/index'
// import LikeTask from '@/components/twitter/likeTask/index'
// import ForwardTask from '@/components/twitter/forwardTask/index'
import { getStatusType, getStatusLabel, getStatusTip } from '@/utils/status'
import { pickerOptions } from '@/utils/dateTimePicker'
import Clipboard from 'clipboard'
import groupDialog from '@/views/group/components/Group_account.vue'
import ytbDetailDialog from './components/YoutubeDialog.vue'

export default {
  components: { PostTask, tagDialog, groupDialog, ytbDetailDialog },
  data() {
    return {
      searchType: false,
      searchHeight: 0,
      userInfo: this.$store.state.user.userInfo,
      isshow: false,
      accountId: 0,
      list: null,
      listLoading: true,
      queryForm: {
        pageIndex: 1,
        pageSize: 10,
        id: '',
        is_risk: '',
        is_test: '',
        status: 1,
        user: '',
        search: ''
      },
      count: 0,
      accountDialog: false,
      userList: [],
      current: 1,
      // 每页记录数
      limit: 5,
      // 总记录数
      total: 0,
      postTaskDialogShow: false,
      forwardTaskDialogShow: false,
      commentTaskDialogShow: false,
      likeTaskDialogShow: false,
      statusMap: {
        1: {
          type: 'success',
          label: '正常'
        },
        0: {
          type: 'warning',
          label: '异常'
        },
        '-1': {
          type: 'danger',
          label: '失效',
          tip: '该账号因为异常行为已被暂停使用'
        }
      },
      pickerOptions: pickerOptions,
      copyValue: 'value',
      groupOptions: [] //组
    }
  },
  computed: {
    windowsHeight() {
      return this.$store.state.windowData.height
    }
  },
  created() {
    this.fetchData()
    this.getGroup()
  },
  mounted() {
    const roles = window.localStorage.getItem('roles')
    if (roles && roles.indexOf('ADMIN') !== -1) {
      // 如果是管理员 显示查看
      this.isshow = true
    } else {
      // 如果不是管理员 隐藏查看
      this.isshow = false
    }
    this.$nextTick(() => {
      let dom = this.$refs.searchFormRef
      this.searchHeight = dom.$el.offsetHeight
    })
  },
  methods: {
    // 展开搜索条件
    showSearchFun() {
      this.searchType = !this.searchType
      this.$nextTick(() => {
        let dom = this.$refs.searchFormRef
        this.searchHeight = dom.$el.offsetHeight
      })
    },
    // hanleTimeDelay,
    getStatusType,
    getStatusLabel,
    getStatusTip,
    async fetchData() {
      this.listLoading = true
      let obj = { ...this.queryForm }
      if (obj.group == 'w') {
        delete obj.group
        obj.group__isnull = true
      } else {
        delete obj.group__isnull
      }
      const { all_count: count, items } = await getAccountList(obj)
      this.count = count
      this.list = items
      this.listLoading = false
    },
    async handleSearch() {
      this.queryForm.pageIndex = 1
      await this.fetchData()
    },
    handleSizeChange(pageSize) {
      this.queryForm.pageSize = pageSize
      // 分页变更时处理
      this.fetchData()
    },
    handleCurrentChange(pageIndex) {
      this.queryForm.pageIndex = pageIndex
      // 分页变更时处理
      this.fetchData()
    },
    handlePostTask(index, row) {
      this.postTaskDialogShow = true
      this.accountId = row.id
      // this.setDefaultTime()
      // this.postTaskForm.publishTime = getAfterTime(10 * 60)
    },
    // handleForwardTask(index, row) {
    //   this.forwardTaskDialogShow = true
    //   this.accountId = row.id
    //   // this.forwardTaskForm.forwardTime = getAfterTime(10 * 60)
    // },
    // handleCommentTask(index, row) {
    //   this.commentTaskDialogShow = true
    //   this.accountId = row.id
    // },
    // commentDialogStatus(status) {
    //   this.commentTaskDialogShow = status
    // },
    // handleLikeTask(index, row) {
    //   this.likeTaskDialogShow = true
    //   this.accountId = row.id
    // },
    // likeDialogStatus(status) {
    //   this.likeTaskDialogShow = status
    // },
    postTaskDialogStatus(status) {
      this.postTaskDialogShow = status
    },
    // forwardTaskDialogStatus(status) {
    //   this.forwardTaskDialogShow = status
    // },
    // 获取账号使用者
    handleAccount(row) {
      // console.log(row)
      this.accountDialog = true
      this.accountId = row.id
      // console.log(this.accountId)
      this.current = 1
      this.getAccountData()
    },
    // 账号使用接口
    getAccountData() {
      const obj = {
        pageIndex: this.current,
        pageSize: this.limit
      }
      getAssignByAccount(this.accountId, obj).then(res => {
        // console.log(res)
        this.userList = res.items
        this.total = res.all_count
      })
    },
    // 分页
    currentChange(val) {
      this.current = val
      this.getAccountData()
    },
    sizeChange(val) {
      this.limit = val
      this.getAccountData()
    },

    closePostTaskDialog() {
      this.$refs.postTaskForm.resetFields()
      this.postTaskForm.publishTime = null
      this.postTaskDialogShow = false
    },
    clickClosePostTaskDialog(done) {
      // this.closePostTaskDialog()
      done()
    },
    // closeForwardTaskDialog() {
    //   this.$refs.forwardTaskForm.resetFields()
    //   this.forwardTaskForm.forwardTime = null
    //   this.forwardTaskDialogShow = false
    // },
    // clickCloseForwardTaskDialog(done) {
    //   // this.closeForwardTaskDialog()
    //   done()
    // },
    // clickCloseCommentTaskDialog(done) {
    //   done()
    // },
    cellClick(row) {
      row.edit = true
    },
    async saveEdit(accountId, data) {
      // row.edit = false
      await updateAccount(accountId, data)
      this.fetchData()
    },
    // 点击复制
    copy(value) {
      const that = this
      that.copyValue = value
      var clipboard = new Clipboard('.tag-read')
      clipboard.on('success', e => {
        this.$message.success('复制成功！')
        //  释放内存
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        // 不支持复制
        this.$message.error('该浏览器不支持复制!')
        // 释放内存
        clipboard.destroy()
      })
    },
    // 打标记
    markFun(row) {
      let arr
      if (row.tag_links_entity) {
        arr = row.tag_links_entity.map(item => {
          return item.tag.id
        })
      }
      this.$refs.tagDialogRef.initFun(row.id, arr)
    },
    // 取消标签
    closeTag(row) {
      this.$confirm('删除该标签, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delTagApi(row.id).then(() => {
            this.fetchData()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    // 分组
    groupFun(row) {
      let group = row.group_entity ? row.group_entity.id : null
      this.$refs.groupDialogRef.initFun(row.id, 'youtube', group)
    },
    // 组数据
    getGroup() {
      group_all_Api().then(data => {
        this.groupOptions = data
      })
    },
    // 取消分组
    closeGroup(row) {
      this.$confirm('将账号移出该分组，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          updateAccount(row.id, { group: null }).then(() => {
            this.fetchData()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    // 详情
    detailFun(row) {
      this.$refs.ytbDetailDialogRef.initFun(row)
    },
    // 新建标签
    createTag(row) {
      this.$set(row, 'tag_type', true)
      this.$nextTick(() => {
        this.$set(row, 'tagName', '')
        this.$refs['inputField' + row.id].focus()
      })
    },
    // 失去焦点
    inputBlurFun(row) {
      this.$set(row, 'tag_type', false)
      if (!row.tagName || row.tagName.trim() == '') {
        // this.$message.warning('请输入标签名！')
        return
      }
      get_or_createApi({
        id: row.id,
        tag: row.tagName
      }).then(data => {
        addTagApi({
          tag: data.id,
          youtube_account: row.id
        }).then(() => {
          // this.$message.success('提交成功！')
          this.dialogVisible = false
          this.fetchData()
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.search-header {
  display: flex;
  flex-wrap: wrap;
  ::v-deep .el-form-item {
    width: calc(100% / 6 - 10px);
    margin-right: 10px;
    overflow: hidden;
    .el-form-item__content {
      width: calc(100% - 80px);
    }
  }
}
</style>
