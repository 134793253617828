<template>
  <div>
    <el-form
      ref="postTaskForm"
      :model="postTaskForm"
      :rules="postTaskFormRules"
      label-width="80px"
      v-loading="uploadLoading"
    >
      <el-form-item label="视频标题" prop="title">
        <el-input
          :rows="4"
          placeholder="请输入标题"
          v-model="postTaskForm.title"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="视频简介"
        prop="content"
        style="white-space: nowrap; "
      >
        <el-input
          style="width: calc(100% - 130px);"
          type="textarea"
          :rows="4"
          placeholder="请输入视频简介"
          v-model="postTaskForm.content"
        ></el-input>
        <el-button
          size="mini"
          style="margin-left: 20px; "
          type="primary"
          @click="openTranslate"
        >
          选择语料
        </el-button>
        <translatePage ref="translatePageRef" @languageFun="languageFun" />
      </el-form-item>
      <el-form-item label="素材选择" prop="media">
        <MaterialSelect
          v-on:getChildMaterial="getChildMaterial"
        ></MaterialSelect>
      </el-form-item>
      <el-form-item label="发表时间" prop="publish_time">
        <el-date-picker
          v-model="postTaskForm.publish_time"
          type="datetime"
          placeholder="选择日期时间"
          align="right"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd HH:mm:ss"
          :editable="false"
        ></el-date-picker>
        <span>说明：发表时间为空时则立即发表</span>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="postTaskFormSubmit"
          :loading="postTaskFormLoading"
        >
          立即创建
        </el-button>
        <el-button @click="closePostTaskDialog">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { hanleTimeDelay } from '@/utils/validate'
import { Message } from 'element-ui'
import { myUpload } from '@/plugins/upload'

import { pickerOptions, getAfterTime } from '@/utils/dateTimePicker'
import { addPostTask } from '@/api/youTube'
import MaterialSelect from '@/components/material/MaterialPage'

export default {
  name: 'PostTask',
  props: { accountId: Number },
  components: { MaterialSelect },

  data() {
    return {
      uploadLoading: false,
      postTaskForm: {
        content: '',
        account: this.accountId,
        publish_time: null,
        media: null,
        // mediaId: null,
        mediaMaterial: null
      },
      postTaskFormRules: {
        title: [
          {
            required: true,
            trigger: 'blur',
            message: '标题不能为空'
          }
        ],
        media: [
          {
            required: true,
            trigger: 'blur',
            message: '视频地址不能为空'
          },
          {
            required: true,
            trigger: 'change',
            message: '视频地址不能为空'
          }
        ],
        publish_time: [
          {
            trigger: 'blur',
            message: '不能小于当前时间',
            validator: this.hanleTimeDelay
          }
        ]
      },
      pickerOptions: pickerOptions,
      postTaskFormLoading: false,
      uploadFile: null,
      remark: ''
    }
  },
  created() {
    // this.setDefaultTime()
  },
  methods: {
    hanleTimeDelay,
    getAfterTime,
    async postTaskFormSubmit() {
      await this.$refs.postTaskForm.validate(async valid => {
        if (valid) {
          this.postTaskFormLoading = true
          this.postTaskForm.account = this.accountId
          try {
            await addPostTask(this.postTaskForm)
            this.closePostTaskDialog()
          } catch (error) {
          } finally {
            this.postTaskFormLoading = false
          }
        } else {
          return false
        }
      })
    },
    closePostTaskDialog() {
      this.$refs.postTaskForm.resetFields()
      this.postTaskForm.publish_time = null
      this.postTaskDialogShow = false
      this.$emit('dialogStatus', false)
    },
    setDefaultTime() {
      this.postTaskForm.publish_time = getAfterTime(10 * 60)
    },
    handleUploadFile(file) {
      console.log('操作文件上传', file)
      // this.fileForm = new FormData()
      // this.fileForm.append('file', file.raw)
      const size = file.size / 1024 / 1024
      if (size > 300) {
        Message.warning('文件大小必须小于300M')
        return
      }
      this.uploadFile = file
    },
    async submitUpload() {
      if (this.uploadFile) {
        // TODO 文件上传回调
        this.uploadLoading = true
        const result = await myUpload(this.uploadFile)
        this.dialogVisible = false
        // console.log('文件上传结果', response)
        this.postTaskForm.media = result.url
        this.uploadFile = null
        this.uploadLoading = false
      } else {
        Message.warning('请先选择文件')
      }
    },
    getChildMaterial(material) {
      this.mediaMaterial = material
      this.postTaskForm.media = material.id
      // this.postTaskForm.mediaId = material.id
    },
    // 语料
    languageFun(text) {
      this.postTaskForm.content = text
    },
    openTranslate() {
      this.$refs.translatePageRef.initFun()
    }
  }
}
</script>
